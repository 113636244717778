<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="确定" width="52.5%"
    @ok="handleOk" @cancel="handleCancel">
      <div class="table">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-table size="small" :pagination="false" :bordered='false' rowKey="id"
          :columns="columns" :dataSource="list">
        </a-table>
      </div>
  </a-modal>
</template>

<script>
const columns = [
  { title: '#', width:'55px', customRender:(text,record,index)=>`${index+1}`, key: 'index' },
  { title: '学员名称', dataIndex: 'student_name', key: 'name' },
  // { title: '性别', dataIndex: 'brief', key: 'age'},
  // { title: '联系方式', dataIndex: 'studio_id', key: '1' },
  { title: '推送状态', dataIndex: 'is_notify', key: '2' },
  { title: '阅读状态', dataIndex: 'is_read', key: '3' ,align:'center'},
  { title: '通知时间', dataIndex: 'created_at', key: '4' },
  { title: '阅读时间', dataIndex: 'read_at', key: '5' },
]
							
export default {
  name: 'AddModal',
  inject: ['parent'],
  props: {
    item: Object
  },

  data() {
    return {
      loading:false,
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      ModalTitle: '消息通知情况',
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      columns,
      list: [],
    }
  },

  created() {
    this.visible = true
    this.getList()
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    async getList() {
      this.loading = true
      let {notify_id} = this.item
      let res = await this.$store.dispatch('notificationCenterNotifyListAction', {data: {notify_id}})
      this.list = res.items
      this.loading = false
    },
    handleOk() {
      this.parent.hideListModal(1)
    },
    handleCancel() {
      this.parent.hideListModal(0)
    }
  }
}
</script>