<template>
    <div @click.stop class="detail-mobile">
        <img style="width:350px" src="@/assets/mobile-iphone.png">
        <div class="detail-mobile-box">
            <div class="detail-mobile-box-nav">
                <LImg style="width:36px;height:36px;border-radius:50%;margin-right:8px" :src="app_avatar"/>
                <span>{{app_name}}</span>
            </div>
            <div class="detail-mobile-box-title">
                <h3>{{item.title}}</h3>
                <div>{{item.dateline}}</div>
            </div>
            <div class="detail-mobile-box-html" v-html="item.content"></div>
        </div>
    </div>
</template>

<script>
    import moment from 'moment'
    export default {
        name:'detailModal',
        inject: ['parent'],
        props: {
            item: Object
        },
        data() {
            return {
                app_name:this.$store.getters.brand_info.app_name,
                app_avatar:this.$store.getters.brand_info.app_avatar,
            }
        },
        methods: {
            moment,
        },
    }
</script>

<style lang="scss">
    .detail-mobile{
        position: fixed!important;
        left: 50%;
        top: 60px;
        margin-left: -175px;
        z-index: 4100;
        border-radius: 118px;
        &-box{
            width: 330px;
            height: 562px;
            background: #fff;
            position: absolute;
            top: 70px;
            left: 9px;
            overflow-y: auto;
            padding:5px;
            &-title{
                margin-top: 10px;
            }
            &-html{
                margin-top: 10px;
                img{
                width: 100%;
                }
            }
        }
    }
</style>