<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="推送" width="62.5%" height='600px'
    @ok="handleOk" @cancel="handleCancel">
      <a-row>
        <a-col :span="10">
          <div class="message-mobile">
            <img src="@/assets/mobile-iphone.png">
            <div class="message-mobile-box">
              <div class="message-mobile-box-nav">
                <LImg style="width:48px;height:48px;border-radius:50%;margin-right:8px" :src="app_avatar"/>
                <span>{{app_name}}</span>
              </div>
              <div class="message-mobile-box-title">
                <h3>{{app_title?app_title:'通知标题'}}</h3>
                <div>{{moment().format('YYYY-MM-DD')}}</div>
              </div>
              <div class="message-mobile-box-html" v-html="app_content?app_content:'<p>通知内容</p>'"></div>
            </div>
          </div>
        </a-col>
        <a-col :span="14">
          <a-form :form="form">
            <a-form-item v-for="(item, index) of formData" :key="index" 
              :label="item.label" :label-col="labelCol" :wrapper-col="wrapperCol">
              <a-input @change="handleTitle" v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
              <a-date-picker v-if="item.type === 'date'" style="width: 100%"  v-decorator="[item.name, { rules: item.rules }]" format="YYYY-MM-DD"/>
              <a-select v-else-if="item.type === 'select'" placeholder="请选择"
                v-decorator="[item.name, { rules: item.rules }]">
                <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
              </a-select>
              <a-select v-else-if="item.type === 'select2'" mode="multiple"
                showSearch v-decorator="[item.name, { rules: item.rules }]"
                :defaultActiveFirstOption="false"
                :showArrow="false"
                :filterOption="false"
                @search="handleSearch"
                @change="handleChange"
                :notFoundContent="null"
              >
                <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">
                  <div>
                      <LImg style="width:24px;height:24px;border-radius:50%;margin-right:8px" :src="d['student_avatar']"/>
                      <span>{{ d[item.items.label] }}</span>
                  </div>
                </a-select-option>
              </a-select>

              <div v-else-if="item.type === 'classes'">
                <a-button icon="plus" type="dashed" @click="shwoClassesModal">通知班级</a-button>
                <div>
                  <a-tag closable @close="handleCloseClasses(index)" v-for="(item,index) in classesList" :key='item.class_id'>{{item.class_name}}</a-tag>
                </div>
              </div>
  
              <a-textarea v-else-if="item.type === 'textarea'" :rows="2" v-decorator="[item.name, { rules: item.rules }]"/>
              <l-editor v-else-if="item.type === 'textarea2'" name="content" v-decorator="[item.name, { rules: item.rules }]" :data="editor.content"/>
            </a-form-item>
          </a-form>
        </a-col>
      </a-row>
      <classesModal v-if="isClassesModal" :item="modalData"/>
  </a-modal>
</template>

<script>
import moment from 'moment'
import classesModal from '@/views/students/zcxy/classesModal'
const formData = [
  {
    name: 'class_id',
    label: '通知班级',
    type: 'classes',
    rules: [],
    items: {
      data: 'classes',
      label: 'filter_name',
      value: 'class_id'
    }
  },
  {
    name: 'post_id',
    label: '通知学员',
    type: 'select2',
    rules: [],
    items: {
      data: 'students',
      label: 'student_name',
      value: 'student_id'
    }
  },
  {
    name: 'title',
    label: '通知标题',
    type: 'input',
    rules: [{ required: true, message: '请输入通知标题!' }]
  },
  {
    name: 'brief',
    label: '通知摘要',
    type: 'textarea',
    rules: [{ required: true, message: '请输入通知摘要!' }]
  },
  {
    name: 'content',
    label: '通知内容',
    type: 'textarea2',
    rules: [{ required: true, message: '请填写通知内容!' }]
  },
]
export default {
  name: 'EditModal',
  provide() {
    return {
      parent: this
    }
  },
  inject: ['parent'],
  props: {
    item: Object
  },
  components:{ classesModal },
  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
      ModalTitle: '新增通知',
      visible: false,
      confirmLoading: false,
      formData,
      form: this.$form.createForm(this),
      seleteItems: {
        classCategorys: []
      },
      content: '',
      editor: {},
      app_name:this.$store.getters.brand_info.app_name,
      app_avatar:this.$store.getters.brand_info.app_avatar,
      app_title:'',
      app_content:'',
      classesList:[],
      modalData: {},
      isClassesModal:false
    }
  },

  async created() {
    this.visible = true
    this.$ls.remove('localClassesList')
    await this.$nextTick()
    await this.getClassCategory()
    if (this.item) {
      this.ModalTitle = '编辑通知'
      let item = await this.getDetail(this.item.notify_id)
      let formFields = {}
      for (let d of formData) {
        formFields[d.name] = item[d.name]
      }
      this.editor.content = item['content']
      this.form.setFieldsValue(formFields)
    }
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    moment,
    async getClassCategory() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.seleteItems.classCategorys = res.data
    },
    async getDetail(notify_id) {
      let res = await this.$store.dispatch('roomDetailAction', {data: {notify_id}})
      return res.data
    },
    changeEditor(key, value) {
      this.editor[key] = value
      this.app_content = value
      this.form.setFieldsValue({content:value})
    },
    async handleOk() {
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if(this.classesList.length == 0){
          if(params.post_id){
              if(params.post_id.length ==0){
                this.$message.warning('请选择学员或者班级!!')
                return false
              }
            }else{
              this.$message.warning('请选择学员或者班级!!')
              return false
            }
        }else{
          let class_id = []
          this.classesList.forEach(item=>{
            class_id.push(item.class_id)
          })
          params.class_id = class_id
        }
        if (params) {
          if (item) {
            params.notify_id = item.notify_id
          }
          this.confirmLoading=true
          params.content = this.editor.content
          let res = await this.$store.dispatch(item ? 'notificationCenterUpdateAction' : 'notificationCenterAddAction', { data: params })
            .then(res=>{
              this.$message.success('操作成功!')
              form.resetFields()
              this.parent.hideEditModal(1)
            })
            .catch(err=>{
              this.$message.error('操作失败!')
              this.confirmLoading=false
            })
        }
      } catch {
      }
    },
    handleCancel() {
      this.parent.hideEditModal(0)
    },

    async handleSearch(value) {
      let params = {
        "q": value,
      }
      let res = await this.$store.dispatch('searchStudentsAction', { params })
      this.seleteItems.students = res.data
      this.$forceUpdate()
    },
    handleChange(value) {
      Object.assign(this, {
        value,
        data: [],
        fetching: false,
      });
    },
    handleTitle(e){
      this.app_title = e.target.value
    },
    shwoClassesModal(){
      this.isClassesModal = true
    },
    hideClassesModal(type){
      if(type == 1){
        let classesList = this.$ls.get('localClassesList') || []
        let list = []
        if(classesList.length > 0){
          classesList.forEach(item=>{
            list.push(item.class_id)
          })
          list = Array.from(new Set(list))
          list.forEach((item,index)=>{
            classesList.forEach(it=>{
              if(item == it.class_id){
                list[index] = it
              }
            })
          })
          this.$ls.set('localClassesList', list)
          this.classesList = list
        }
      }
      this.isClassesModal = false
    },
    handleCloseClasses(index){
      this.classesList.splice(index,1)
      this.$ls.set('localClassesList', this.classesList)
    }
  }
}
</script>
<style lang="scss">
  .message-mobile{
    position: relative;
    &-box{
      width: 400px;
      height: 670px;
      // background: rgba(0,0,0,0.1);
      background: #fff;
      position: absolute;
      top: 90px;
      left: 12px;
      overflow-y: auto;
      padding: 5px;
      &-title{
        margin-top: 10px;
      }
      &-html{
        margin-top: 10px;
        img{
          width: 100%;
        }
      }
    }
  }
</style>
