<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>校区管理</a-breadcrumb-item>
      <a-breadcrumb-item>通知中心</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-box">
      <div class="clearfix table-tools">
        <div class="buttons">
          <a-form layout='inline'>
            <a-form-item v-if="canSave">
            <a-button icon="plus" type="primary" @click="showEditModal()">新增通知</a-button>
            </a-form-item>
          </a-form>
        </div>
        <div class="search">
          <a-form layout='inline' @submit="searchList">
            <a-form-item>
              <a-tooltip placement="topLeft" >
                <template slot="title">
                  <span>通知标题</span>
                </template>
                <a-input allowClear v-model.trim="searchData.title" placeholder="通知标题" style="width: 160px"/>
              </a-tooltip>
            </a-form-item>
            <a-form-item v-if="canIndex">
              <a-button type="primary" html-type="submit" :loading="loading" @click="searchList">搜索</a-button>
            </a-form-item>
            <a-form-item>
              <a-popover placement="bottom" trigger="click" v-model="visible">
                <template slot="content">
                  <div class="more-search">
                    <a-form layout='inline'>
                      <a-form-item class="block-line" label="通知时间" >
                        <a-range-picker :ranges="rangesData" :disabled-date="disabledDate" allowClear v-model="searchData.dateline" :placeholder="['开始时间', '结束时间']"/>
                      </a-form-item>
                      <a-form-item class="block-line" label="通知校区" >
                        <a-select allowClear v-model="searchData.studio_id" placeholder="请选择" showSearch :filterOption="filterOption">
                          <a-select-option v-for="(d, index) of studios" :key="index" :value="d['studio_id']">{{ d['filter_name'] }}</a-select-option>
                        </a-select>
                      </a-form-item>
                    </a-form>
                    <div class="more-search__buttons">
                      <a-button type="dashed" @click="closeSearch">取消</a-button>
                      <a-button @click="reset">重置</a-button>
                      <a-button v-if="canIndex" type="primary" @click="searchList">搜索</a-button>
                    </div>
                  </div>
                </template>
                <a-button>更多<a-icon type="down" /></a-button>
              </a-popover>
            </a-form-item>
          </a-form>
        </div>
      </div>
      <div class="table">
        <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
        <a-table size="middle" :pagination="false" :bordered='false' rowKey="notify_id"
          :columns="columns" :dataSource="list">
          <template slot="index" slot-scope="text, record , index">
            <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template>
          <template slot="name" slot-scope="text,record">
            <a href="javascript:;" @click="showDetailModal(record)">{{ text }}</a>
          </template>
          <template slot="studio_names" slot-scope="text, record">
              <span v-if="record.studio_names.length === 0">--</span>
              <a-dropdown v-else>
                  <div style="cursor:pointer" class="ant-dropdown-link" @click="e => e.preventDefault()">
                      {{record.studio_names[0]}}等{{record.studio_names.length}}个校区<a-icon type="caret-down" />
                  </div>
                  <a-menu style="max-height:400px;overflow-y: auto;" slot="overlay">
                      <a-menu-item style="text-align: center" v-for="(it,j) in record.studio_names" :key="j">
                          {{it}}
                      </a-menu-item>
                  </a-menu>
              </a-dropdown>
          </template>
          <template slot="read_status" slot-scope="text,record">
            <a href="javascript:;" @click="showListModal(record)">{{ text }}</a>
          </template>

          <template slot="action" slot-scope="text,record">
            <a href="javascript:;" @click="resendItem(record)">通知</a>
            <a-divider type="vertical" />
            <a v-if="canDelete" href="javascript:;" @click="deleteItem(record)">删除</a>
          </template>
        </a-table>
      </div>
      <div class="page">
        <a-pagination
          :pageSizeOptions="pageSizeOptions"
          :total="pageParams.totalCount"
          showSizeChanger
          :pageSize="pageParams.perPage"
          v-model="current"
          @change="changePage"
          @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
        >
            <template slot='buildOptionText' slot-scope='props'>
              <span v-if="props.value!=='100'">{{props.value}}条/页</span>
              <span v-if="props.value==='100'">100条/页</span>
            </template>
          </a-pagination>
      </div>
    </div>
    <editModal v-if="editVisible" :item="modalData"/>
    <listModal v-if="listVisible" :item="modalData"/>
    <detailModal v-if="detail_visible" :item="modalData"/>
    <div @click="handleDetailOk" v-if="detail_visible" class="message-bg"></div>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index' },
  { title: '通知标题',  dataIndex: 'title', key: 'name' ,scopedSlots: { customRender: 'name' } },
  { title: '通知摘要',  dataIndex: 'brief', key: 'brief'},
  { title: '通知校区', dataIndex: 'studio_names', key: 'studio_names', scopedSlots: { customRender: 'studio_names' },align:'center'},
  { title: '阅读率', dataIndex: 'read_status', key: '2', scopedSlots: { customRender: 'read_status' } },
  { title: '操作管理员', dataIndex: 'created_by', key: '3',align:'center' },
  { title: '通知时间', dataIndex: 'dateline', key: '5' },
  { title: '操作', key: 'operation', width: 100, fixed: 'right', scopedSlots: { customRender: 'action' }},
]

import C_ITEMS from '@/utils/items'
import editModal from './editModal'
import listModal from './listModal'
import detailModal from './detailModal'
import ranges from "@/common/mixins/ranges";
import authority from '@/common/mixins/authority'
import moment from 'moment'
import tableMixins from '@/common/mixins/table'

export default {
  name: 'Contract',
  provide() {
    return {
      parent: this
    }
  },

  components: {
    editModal,
    listModal,
    detailModal,
  },

  data() {
    return {
      loading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,

      studios: [],

      searchData: {},
      visible: false,
      modalData: {},
      editVisible: false,
      listVisible: false,
      detail_visible:false,
      authType:['orginization','notification'],
    }
  },
  mixins: [ ranges, authority, tableMixins ],
  created() {
    this.getStudio()
  },

  methods: {
    filterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    closeSearch() {
      this.visible = false
    },
    async getStudio() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.studios = res.data
    },

    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          if (k === 'dateline') {
            obj['start_date'] = moment(searchData[k][0]).format('YYYY-MM-DD')
            obj['end_date'] = moment(searchData[k][1]).format('YYYY-MM-DD')
          } else {
            obj[k] = searchData[k]
          }
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('notificationCenterAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },

    showEditModal(item) {
      this.modalData = item
      this.editVisible = true
    },
    hideEditModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.editVisible = false
    },

    showListModal(item) {
      this.modalData = item
      this.listVisible = true
    },
    hideListModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.listVisible = false
    },
    showDetailModal(item){
      this.modalData = item
      this.detail_visible = true
    },
    handleDetailOk(e) {
      this.detail_visible = false
    },
    resendItem(item) {
      let that = this
      this.$confirm({
        title: `您确定要通知"${item.title}"吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('notificationCenterResendAction', { data: { notify_id: item.notify_id } })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
    
    deleteItem(item) {
      let that = this
      this.$confirm({
        title: `您确定要删除"${item.title}"吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('notificationCenterDeleteAction', { data: { notify_id: item.notify_id } })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
  }
}
</script>
<style lang="scss">
  .notiDetailModal{
    img{
      width: 100%;
    }
  }
  .message-bg{
      position: fixed;
      z-index: 4000;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0,0,0,0.4);
  }
</style>